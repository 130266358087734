import Vue from 'vue'
import Router from 'vue-router'
// import store from '@/store'
import { getAdminPower } from '@/apiUtils'
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
Vue.use(Router)

const router = new Router({
	routes: [
		{
			path: '/',
			redirect: '/home'
		},
		{
			path: '/',
			component: () => import('../components/common/Home.vue'),
			meta: { title: '自述文件' },
			children: [
				{
					path: '/home',
					component: () => import('../components/page/Home/index.vue'),
					meta: { title: '华立园-项目管理系统' }
				},
				{
					path: '/admin',
					component: () => import('../components/page/Admin/index.vue'),
					meta: { title: '管理员管理' }
				},
				{
					path: '/myInfo',
					component: () => import('../components/page/MyInfo/index.vue'),
					meta: { title: '我的信息' }
				},
				{
					path: '/404',
					component: () => import('../components/page/404/index.vue'),
					meta: { title: '404' }
				},
				{
					path: '/403',
					component: () => import('../components/page/403/index.vue'),
					meta: { title: '403' }
				},
				{
					path: '/createProject',
					component: () => import('../components/page/CreateProject/index.vue'),
					meta: { title: '创建项目' }
				},
				{
					path: '/projectManage',
					component: () => import('../components/page/ProjectManage/index.vue'),
					meta: { title: '项目管理' }
				},
				{
					path: '/projectDetail',
					component: () => import('../components/page/ProjectDetail/index.vue'),
					meta: { title: '项目详情' }
				},
				{
					path: '/projectView',
					component: () => import('../components/page/ProjectView/index.vue'),
					meta: { title: '项目视图' }
				},
				{
					path: '/Personnel',
					component: () => import('../components/page/Personnel/index.vue'),
					meta: { title: '人员管理' }
				},
				// {
				// 	path: '/importPersonnel',
				// 	component: () => import('../components/page/ImportPersonnel/index.vue'),
				// 	meta: { title: '人员批量管理' }
				// },
				{
					path: '/identityType',
					component: () => import('../components/page/IdentityType/index.vue'),
					meta: { title: '身份类型管理' }
				},
				{
					name: 'sourceCodeBackup',
					path: '/sourceCodeBackup',
					component: () => import('../components/page/SourceCodeBackup/index.vue'),
					meta: { title: '源码存档' }
				},
				{
					name: 'sourceCodeBackupCreate',
					path: '/sourceCodeBackupCreate',
					component: () => import('../components/page/SourceCodeBackup/Create.vue'),
					meta: { title: '新增存档' }
				},
				{
					name: 'sourceCodeBackupEdit',
					path: '/sourceCodeBackupEdit',
					component: () => import('../components/page/SourceCodeBackup/Edit.vue'),
					meta: { title: '编辑存档' }
				},
				{
					name: 'tagManage',
					path: '/tagManage',
					component: () => import('../components/page/TagManage/index.vue'),
					meta: { title: '标签管理' }
				},
				{
					name: 'demandManage',
					path: '/demandManage',
					component: () => import('../components/page/DemandManage/index.vue'),
					meta: { title: '需求管理' }
				},
				{
					name: 'apiManage',
					path: '/apiManage',
					component: () => import('../components/page/ApiManage/index.vue'),
					meta: { title: '接口管理' }
				},
				{
					name: 'memberManage',
					path: '/memberManage',
					component: () => import('../components/page/MemberManage/index.vue'),
					meta: { title: '成员管理' }
				},
				{
					name: 'eventManage',
					path: '/eventManage',
					component: () => import('../components/page/EventManage/index.vue'),
					meta: { title: '事件管理' }
				},
				{
					name: 'eventManageCreateEventItem',
					path: '/eventManageCreateEventItem',
					component: () => import('../components/page/EventManage/CreateEventItem.vue'),
					meta: { title: '添加事项' }
				},
				{
					name: 'platformManage',
					path: '/platformManage',
					component: () => import('../components/page/PlatformManage/index.vue'),
					meta: { title: '平台管理' }
				},
				{
					name: 'annex',
					path: '/annex',
					component: () => import('../components/page/Annex/index.vue'),
					meta: { title: '附件视图' }
				}
			]
		},
		{
			path: '/login',
			component: () => import('../components/page/Login/index.vue'),
			meta: { title: '登录' }
		},
		{
			path: '*',
			redirect: '/404'
		}
	]
})

const check = () => {
	// 需要检查的路由
	let checkingRoute = null

	const f = () => {
		checkingRoute = new Promise(async (resolve) => {
			// 登录时已写入当前管理员的ID, 若无应检查登录模块处
			const id = sessionStorage.getItem('adminId')
			const { data = [] } = await getAdminPower({ id: id })
			const powerList = []
			const power = data.power || []
			power.forEach((item) => {
				const children = item.children || []
				children.forEach((el) => {
					powerList.push(el.power)
				})
			})
			resolve(new Set(powerList))
		})
	}

	// 白名单路由
	const whitelist = ['/login', '/home', '/403', '/404', '/myInfo']

	router.beforeEach(async (to, from, next) => {
		// 如果从 login 页面进入, 则重新获取最新的 [检查路由]
		if (from.path === '/login') {
			f()
		}

		// 如果已登录刷新页面, 则获取最新的 [检查路由]
		if (checkingRoute === null) {
			f()
		}

		const list = await checkingRoute

		// 白名单放行
		if (whitelist.includes(to.path)) {
			next()
			return
		}

		// 拦截无权限访问路由
		if (!list.has(to.path.substring(1))) {
			console.warn(to.path, '无权限访问, 已被拦截')
			next('/403')
			return
		}

		// 有权限放行
		next()
	})
}

// check()

export default router
