import BatchImportExcel from './components/BatchImportExcel'
import ExportExcel from './components/ExportExcel'
import UmUploadImage from './components/UmUploadImage'
import UmTag from './components/UmTag'

import Vue from 'vue'
Vue.component('BatchImportExcel', BatchImportExcel)
Vue.component('ExportExcel', ExportExcel)
Vue.component('UmUploadImage', UmUploadImage)
Vue.component('UmTag', UmTag)
