/**
 * 用于处理更加精细的权限控制
 */

import store from '@/store'

const getMap = () => {
	return {
		/** 评价项目管理/操作/查看评价数据 */
		// 'show/projectManage/Operation/viewEvaluateData': 'projectManage-data'
	}
}

/**
 * 初始化权限映射表
 * @param {string[]} powerList 扁平化后的权限列表
 */
export const init = (powerList) => {
	const tempMap = getMap()
	const map = getMap()
	const keys = Object.keys(map)
	keys.forEach((key) => {
		map[key] = null
		powerList.forEach((power) => {
			if (tempMap[key] === power) {
				map[key] = tempMap[key]
			}
		})
	})

	store.dispatch('setPowerMap', map)
	return map
}
