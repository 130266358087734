import router from '@/router'
import ossClient from 'ali-oss'
import request from '../utils/request'
export const url = 'https://admin.project.hlsywy.com'
export const requestUrl = url

/**
 * @typedef Response 响应结果
 * @property {-1|0|1} code 响应状态码 -1 登录过期, 0 请求正常, 1 请求有误
 * @property {string} msg 请求结果描述
 * @property {undefined|Record<string, unknown>|Record<string, unknown>[]} data 请求结果内容
 */

/**
 * @returns {Promise<Response>}
 */
export var fetchData = (query, login) => {
	if (login == 1) {
		var URL = url + '/class' + query.url + '?Token=' + window.sessionStorage.getItem('Token')
	} else {
		var URL = url + '/class' + query.url + '?Token=' + window.sessionStorage.getItem('Token')
		if (process.env.NODE_ENV != 'development') {
			request({
				url: url + '/class/user_loginInfo.cls.php?Token=' + window.sessionStorage.getItem('Token'),
				method: query.method || 'post',
				dataType: 'json',
				data: query.data
			})
				.then((res) => {
					if (res.code != 0) {
						router.push('/login')
					}
				})
				.catch((response) => {
					alert('网络错误')
					router.push('/login')
				})
		}
	}
	var promise = new Promise(function (resolve, reject) {
		request({
			url: URL,
			method: query.method || 'post',
			dataType: 'json',
			data: query.data
		})
			.then((res) => {
				if (res.code == 2) {
					if (confirm(res.msg)) {
						query.data.absolute = 1
						resolve(fetchData(query))
					}
				} else {
					resolve(res)
				}
			})
			.catch((err) => {
				reject(err)
			})
	})
	return promise
}
export const fetchJson = (query) => {
	return request({
		url: query,
		method: 'get',
		params: query
	})
}

function autoLogin() {
	request({
		url: url + '/class/user_loginInfo.cls.php?Token=' + window.sessionStorage.getItem('Token'),
		method: 'post',
		dataType: 'json'
	})
		.then((res) => {
			if (res.code == -1) {
				router.push('/login')
			} else {
				setTimeout(function () {
					autoLogin()
				}, 120000)
			}
		})
		.catch((response) => {
			alert('网络错误')
			router.push('/login')
		})
}

// 保持活跃
// autoLogin()

/**
 * 上传OSS
 * @param {object} res 此处填写上传的Token等信息, 通过 /get_uptoken.cls.php 获取
 * @param {object} file 文件对象
 * @param {string} fileName 上传的文件展示名字
 * @returns 返回一个成功对象, 链接字段为 url
 */
export const upLoadToOss = (res, file, fileName) => {
	var uploadConf = {
		accessKeyId: res.OSSAccessKeyId,
		accessKeySecret: res.signature,
		stsToken: res.SecurityToken,
		endpoint: 'oss-cn-shenzhen.aliyuncs.com',
		bucket: 'dormi'
	}
	return ossClient(uploadConf).put(fileName, file, { ContentType: file.type })
}
