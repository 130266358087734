export { uploadOSS } from '@/utils'
import { fetchData } from '@/api'

/**
 * 获取unc的详细信息
 * @param {string} ucn 统一ID
 * - 10020056184 测试统一ID
 */
export const getUcnInfo = (ucn) =>
	fetchData({
		url: '/get_ucnInfo.cls.php',
		data: {
			UCN: ucn
		}
	})

/**
 * 获取管理员权限详情(展示已有的权限)
 */
export const getAdminPower = (data) =>
	fetchData({
		url: '/get_adminPowerDetail.cls.php',
		data
	})

/**
 * 获取管理员权限id列表(用于修改管理员时还原选项)
 */
export const getAdminPowerId = (data) =>
	fetchData({
		url: '/get_adminPowerId.cls.php',
		data
	})

/** 获取权限选择器 */
export const getPowerSelect = (data) =>
	fetchData({
		url: '/get_powerSelect.cls.php',
		data
	})

/** 获取管理员列表 */
export const getAdminList = (data) =>
	fetchData({
		url: '/get_adminMain.cls.php',
		data
	})

/** 获取个人信息 */
export const getMeInfo = (data) =>
	fetchData({
		url: '/get_myInformation.cls.php',
		data
	})

/** 更新个人信息 */
export const updateMeInfo = (data) =>
	fetchData({
		url: '/update_myInformation.cls.php',
		data
	})

/** 创建管理员 */
export const createAdmin = (data) =>
	fetchData({
		data,
		url: '/create_adminMain.cls.php'
	})

/** 修改管理员 */
export const updateAdmin = (data) =>
	fetchData({
		url: '/update_adminMain.cls.php',
		data
	})

/** 删除管理员 */
export const deleteAdmin = (data) =>
	fetchData({
		url: '/detele_adminMain.cls.php',
		data
	})

/** 获取平台选择器 */
export const getPlatformSelect = (data) =>
	fetchData({
		url: '/get_platformSelect.cls.php',
		data
	})

/** 获取成员选择器 */
export const getMemberSelect = (data) =>
	fetchData({
		url: '/get_memberSelect.cls.php',
		data
	})

/** 获取身份类型选择器 */
export const getIdentitySelect = (data) =>
	fetchData({
		url: '/get_memTypeSelect.cls.php',
		data
	})

/** 创建项目 */
export const createProject = (data) =>
	fetchData({
		url: '/create_projectInfo.cls.php',
		data
	})

/** 创建人员 */
export const createPersonnel = (data) =>
	fetchData({
		url: '/create_memberInfo.cls.php',
		data
	})

/** 删除人员 */
export const deletePersonnel = (data) =>
	fetchData({
		url: '/delete_memberInfo.cls.php',
		data
	})

/** 修改人员 */
export const updatePersonnel = (data) =>
	fetchData({
		url: '/update_memberInfo.cls.php',
		data
	})

/** 获取人员列表 */
export const getPersonnelList = (data) =>
	fetchData({
		url: '/get_memberList.cls.php',
		data
	})

/** 创建身份类型 */
export const createIdentityType = (data) =>
	fetchData({
		url: '/create_memTypeInfo.cls.php',
		data
	})

/** 删除身份类型 */
export const deleteIdentityType = (data) =>
	fetchData({
		url: '/delete_memTypeInfo.cls.php',
		data
	})

/** 修改身份类型 */
export const updateIdentityType = (data) =>
	fetchData({
		url: '/update_memTypeInfo.cls.php',
		data
	})

/** 获取身份类型 */
export const getIdentityTypeList = (data) =>
	fetchData({
		url: '/get_memTypeList.cls.php',
		data
	})

/** 创建源码存档 */
export const createSourceCodeBackup = (data) =>
	fetchData({
		url: '/create_proArchiveInfo.cls.php',
		data
	})
